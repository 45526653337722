<template>
  <div>
    <h2 class="faledia-caption">Flexibler Einsatz von Rechenstrategien</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>
            Beziehungen zwischen Zahlen beim halbschriftlichen Rechnen
            ausnutzen.
          </li>
          <li>
            Sich aufgabenbezogen nach eigenen Präferenzen für eine Strategie des
            halbschriftlichen Rechnens entscheiden.
          </li>
          <li>
            die Auswahl der halbschriftlichen Strategie und das Vorgehen dabei
            erklären und begründen.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Kinder müssen beim halbschriftlichen Rechnen lernen, Beziehungen zwischen
      Zahlen und Aufgaben zu erkennen und flexibel auszunutzen, um erfolgreich
      mit Zahlganzheiten rechnen können. Dadurch haben Lernende langfristig ein
      Strategierepertoire zur Verfügung, woraus sie entsprechend der jeweiligen
      Aufgabe flexibel eine geeignete Strategie oder Mischformen aus den
      Strategien wählen können (Mathe sicher können, o.J.).
    </p>
    <div class="flex">
      <div class="image">
        <AppExpandableImage
          contain
          :src="require('@/assets/hr/Flexibel_Abbildung.png')"
          parentId="swve"
          elementId="swve_darstellungswechsel.png"
        >
        </AppExpandableImage>
      </div>
      <p>
        Kinder nutzen häufig bevorzugt die Strategie, die für sie am einfachsten
        erscheint, um mit dieser alle Aufgaben zu lösen. Dabei können sie jedoch
        zum Teil schnell an ihre Grenzen stoßen. Wird zum Beispiel versucht die
        Strategie Stellenweise aus der Multiplikation direkt auf die Division zu
        übertragen, so führt dies zu Fehlern, wie im nebenstehenden Beispiel.
        Deshalb ist es wichtig, dass Kinder verschiedene Rechenstrategien
        verständnisbasiert kennenlernen, damit sie bei Aufgaben die
        naheliegenden Strategien erkennen sowie anwenden können und somit
        flexibel und sinnvoll – heißt wenig fehleranfällig und effizient –
        rechnen (Götze, Selter, Zannetin, 2019).
      </p>
    </div>
    <p>
      Dabei ist jedoch zu beachten, dass es nicht darum geht, dass alle
      Lernenden sämtliche Strategien bei jeder Aufgabe anwenden können. Das
      Wichtige ist, dass alle Kinder einen für sie geeigneten Weg finden,
      verschiedene Aufgaben zu lösen (Schmassmann, Moser Opitz, 2011; Moser
      Opitz, Scherer, 2010). Voraussetzung dafür, dass halbschriftliche
      Rechenstrategien verständnisbasiert erlernt werden können, sind ein
      gesichertes Zahlverständnis, gesicherte Zahlvorstellungen und dabei
      insbesondere die Zahlbeziehungen und Rechengesetze (Krauthausen, Scherer,
      2007).
    </p>
    <p>
      Wenn die Strategien des halbschriftlichen Rechnens verständnisbasiert
      erarbeitet werden, trägt dies maßgeblich dazu bei, dass Kinder diese
      flexibel anwenden und abhängig von der jeweiligen Aufgabe und eigener
      Präferenzen auswählen können. (Götze, Selter, Zannetin, 2019). Dazu muss
      bei den Kindern der Zahl- und Aufgabenblick gefördert werden, der es
      ermöglicht besondere Zahleigenschaften und Aufgabenbeziehungen
      wahrzunehmen. Zahlen müssen in einer Aufgabe dafür zugleich als Ganzes für
      sich (zum Beispiel: „299 liegt nah an einem glatten Hunderter") und in
      ihrer Beziehung zu anderen Zahlen gesehen werden (zum Beispiel: „401 und
      398 liegen sehr nah beieinander"). Diese Beziehungen können auf der
      Grundlage geltender Operationseigenschaften genutzt werden, um die Zahlen
      dann geschickt zu zerlegen, zu verändern, wieder zusammenzusetzen und
      miteinander zu verrechnen. Nicht für jede Operation gelten dabei die
      gleichen „Regeln". Rechengesetze dürfen nicht unreflektiert von der einen
      auf die andere Operation übertragen werden, sondern müssen an geeigneten
      Beispielen gemeinsam thematisiert werden (5 + 3 = 3 + 5, aber 5 - 3 ≠ 3 -
      5)“ (Selter, Zannetin, 2019). Wenn Sie sich die Rechengesetze noch einmal
      vergegenwärtigen möchten, können Sie nachfolgende Tabelle ausklappen.
    </p>
    <AppButtonCollapse
      messageHidden="Tabelle Rechengesetze anzeigen"
      messageNotHidden="Tabelle Rechengesetze anzeigen"
    >
      <div style="background-color: white">
        <v-row>
          <v-col cols="gesetzeTabelleFormat">
            <!-- Kommutativgesetze-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Kommutativgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Die Summanden einer
                      Additionsaufgabe können vertauscht werden, ohne dass sich
                      dabei das Ergebnis ändert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">8 + 3 = 3 + 8</v-row>
                      <v-row no-gutters justify="center">a + b = b + a</v-row>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Die Faktoren einer
                      Multiplikationsaufgabe können vertauscht werden, ohne dass
                      sich dabei das Ergebnis ändert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">7 · 4 = 4 · 7</v-row>
                      <v-row no-gutters justify="center">a · b = b · a</v-row>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Das <strong>Kommutativgesetz </strong>gilt nicht für
                        die Subtraktion (8 – 4 ist ungleich 4 – 8) und auch
                        nicht für die Division (6 : 3 ist ungleich 3 : 6)</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="gesetzeTabelleFormat">
            <!-- Assoziativgesetz-Tabelle-->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Assoziativgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Die Summanden einer
                      (mehrgliedrigen) Additionsaufgabe können beliebig
                      miteinander verbunden werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >2 + 3) + 1 = 2 + (3 + 1)</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a + b) + c = a + (b + c)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Die Faktoren einer
                      (mehrgliedrigen) Multiplikationsaufgabe können beliebig
                      miteinander verbunden werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >(6 · 5) · 7 = 6 · (5 · 7)</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a · b) · c = a · (b · c)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Das <strong>Assoziativgesetz </strong>gilt nicht für
                        die Subtraktion, denn (8 – 4) – 3 = 4 – 3 = 1 ist
                        ungleich 8 – (4 – 3) = 8 – 1 = 7 und auch nicht für die
                        Division: (36 : 6) : 3 = 6 : 3 = 2 ist ungleich 36 : (6
                        : 3) = 36 : 2 = 18.</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="gesetzeTabelleFormat">
            <!-- Distributivgesetz-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Distributivgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition/ Multiplikation bzw. Division: </strong
                      >Eine Summe wird mit einem Faktor multipliziert (bzw.
                      durch einen Divisor dividiert), indem jeder Summand mit
                      dem Faktor multipliziert (bzw. durch den Divisor
                      dividiert) wird und die Resultate addiert werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >6 · 4 = 2 · 4 + 4 · 4
                      </v-row>
                      <v-row no-gutters justify="center"
                        >(a + b) · c = a · c + b · c
                      </v-row>
                      <v-row no-gutters justify="center"
                        >72 ː 8 = 56 ː 8 + 16 ː 8
                      </v-row>
                      <v-row no-gutters justify="center"
                        >(a + b) ː c = a ː c + b ː c</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong
                        >Subtraktion/ Multiplikation bzw. Division: </strong
                      >Eine Differenz wird mit einem Faktor multipliziert (bzw.
                      durch einen Divisor dividiert), indem Minuend und
                      Subtrahend mit dem Faktor multipliziert (bzw. durch den
                      Divisor dividiert) werden und die Resultate voneinander
                      subtrahiert werden.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >7 · 4 = 10 · 4 – 3 · 4</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a – b) · c = a · c – b · c</v-row
                      >
                      <v-row no-gutters justify="center"
                        >186 ː 6 = 198 ː 6 – 12 ː 6</v-row
                      >
                      <v-row no-gutters justify="center"
                        >(a- b) ː c = a ː c – b ː c</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <i
                        >Analog gilt das <strong>Distributivgesetz </strong>bei
                        der Multiplikation auch, wenn der zweite Faktor
                        „zerlegt“ wird. Die Zerlegung des Divisors funktioniert
                        aber in der Regel nicht.</i
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

          <v-col cols="gesetzeTabelleFormat">
            <!-- Konstanzgesetz-Tabelle -->
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr style="text-align: center">
                    <td colspan="2"><strong>Konstanzgesetz</strong></td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Addition: </strong>Das Ergebnis ändert sich nicht,
                      wenn man eine Zahl vergrößert und die andere entsprechend
                      verkleinert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">3 + 5 = 4 + 4</v-row>
                      <v-row no-gutters justify="center"
                        >a + b = (a + n) + (b – n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Subtraktion: </strong>Das Ergebnis ändert sich
                      nicht, wenn man beide Zahlen um dieselbe Zahl vergrößert
                      (oder verkleinert)
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center">13 – 8 = 14 – 9</v-row>
                      <v-row no-gutters justify="center"
                        >a – b = (a + n) – (b + n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Multiplikation: </strong>Das Ergebnis ändert sich
                      nicht, wenn man eine Zahl multipliziert und die andere
                      entsprechend dividiert.
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >18 · 16 = 36 · 8</v-row
                      >
                      <v-row no-gutters justify="center"
                        >a · b = (a · n) · (b ː n)</v-row
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Division: </strong>Ergebnis ändert sich nicht,
                      wenn man beide Zahlen durch dieselbe Zahl dividiert (oder
                      entsprechend multipliziert).
                    </td>
                    <td class="no-wrap">
                      <v-row no-gutters justify="center"
                        >48 ː 6 = 24 ː 3
                      </v-row>
                      <v-row no-gutters justify="center"
                        >a ː b = (a ː n) ː (b ː n)</v-row
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </AppButtonCollapse>
    <br />
    <p>
      Um diese Flexibilität zu erlangen, ist es nicht ausreichend vermeintliche
      Musterlösungen unreflektiert nachzuvollziehen und bei anderen Aufgaben
      anzuwenden. Vielmehr müssen dafür mit den Kindern das Erkennen und
      Benennen der besonderen Eigenschaften und Beziehungen von Zahlen und
      Aufgaben geübt werden. Hierbei sollte das Reflektieren der Entscheidung
      für einen bestimmten Lösungsweg als wichtiger Bestandteil in den
      Unterricht eingebunden werden (Selter, Zannetin, 2019). Nachfolgend haben
      Sie nun selbst einmal die Gelegenheit nachzuvollziehen, wie anspruchsvoll
      es ist, halbschriftliche Strategien bewusst und reflektiert anzuwenden. Um
      sich in die Lage von Lernenden zu versetzten, werden Ihnen hier Aufgaben
      in anderen Stellenwertsystemen dargeboten. Sollten Sie sich vorher noch
      einmal über den Umgang mit Zahlen in anderen Stellenwertsystemen
      auseinandersetzen wollen, können Sie dies im Inhaltsbereich
      <a
        href="/stellenwertverstaendnis/hintergrundwissen/buendeln-und-entbuendeln"
        >Stellenwertverständnis</a
      >
      tun.
    </p>
    <LernbausteinStempeln
      :id="LBSTStempeln[0].id"
      :workOrder="LBSTStempeln[0].workOrder"
      :image="LBSTStempeln[0].image"
      :audio="LBSTStempeln[0].audio"
      :elements="LBSTStempeln[0].elements"
      :showAlternative="false"
    ></LernbausteinStempeln>
    <LernbausteinStempeln
      :id="LBSTStempeln[1].id"
      :workOrder="LBSTStempeln[1].workOrder"
      :image="LBSTStempeln[1].image"
      :audio="LBSTStempeln[1].audio"
      :elements="LBSTStempeln[1].elements"
      :showAlternative="false"
    ></LernbausteinStempeln>

    <AppLiteraturHR />
    <AppBottomNavHR
      back="/zahlenrechnen/hintergrundwissen/strategien-des-halbschriftlichen-rechnens"
      next="/zahlenrechnen/hintergrundwissen/darstellungsweisen-beim-halbschriftlichen-rechnen"
    />
  </div>
</template>

<script>
import AppBottomNavHR from "@/common/AppBottomNavHR";
import AppLiteraturHR from "@/common/AppLiteraturHR";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppButtonCollapse from "@/common/AppButtonCollapse";

import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LBST_Stempeln_Bsp1 from "@/components/faledia/seiten/zahlenrechnen/LBST/LBST_Stempeln_Bsp1";
import LBST_Stempeln_Bsp2 from "@/components/faledia/seiten/zahlenrechnen/LBST/LBST_Stempeln_Bsp2";

export default {
  components: {
    AppBottomNavHR,
    AppLiteraturHR,
    AppExpandableImage,
    AppButtonCollapse,
    LernbausteinStempeln,
  },
  data: () => ({
    LBSTStempeln: [LBST_Stempeln_Bsp1, LBST_Stempeln_Bsp2],
  }),
};
</script>

<style scoped>
#app .no-wrap {
  white-space: nowrap;
  overflow: auto;
}
.flex {
  display: flex;
  align-items: center;
}
.image {
  max-width: 30%;
  margin-inline-end: 1%;
  margin-block-end: 1%;
}
</style>
