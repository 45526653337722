function createObject() {
  let example = {
    id: "LBST_HR_Stempeln_Bsp2",
    workOrder: "Betrachten Sie die folgende Aufgabe:",
    image: require("@/assets/hr/Stempeln/Bsp2.png"),
    elements: [
      [
        {
          question:
            "Welche halbschriftliche Strategie bietet sich als geschickteste an? Beziehen Sie in Ihre Überlegungen mit ein, dass die Aufgabe im Stellenwertsystem zur Basis 9 ist.",
          answers: [
            {
              correct: true,
              text: "Hilfsaufgabe",
              response: "Genau, diese Strategie halten wir auch für passend.",
            },
            {
              correct: false,
              text: "Vereinfachen",
              response:
                "Wir halten diese Strategie für die gestellte Aufgabe für nicht besonders naheliegend. Minuend und Subtrahend können nicht schnell und einfach gleichsinnig verändert werden, sodass eine leichter zu berechnende Aufgabe entsteht.",
            },
            {
              correct: false,
              text: "Stellenweise",
              response:
                "Wir halten diese Strategie für die gestellte Aufgabe für nicht besonders naheliegend. Bei der Strategie Stellenweise werden Minuend und Subtrahend in ihre Stellenwerte zerlegt und stellengerecht voneinander abgezogen. Bei dieser Aufgabe würde es zu negativen Zwischenergebnissen kommen, was natürlich möglich, aber nicht die unaufwändigste Art ist, um die Aufgabe zu berechnen.",
            },
          ],
        },
      ],
      [
        {
          question: "Warum bietet sich diese Strategie an?",
          answers: [
            {
              correct: true,
              text: "Nähe des Subtrahenden an einer Zahl, die leicht vom Minuenden abgezogen werden kann ",
              response: "<p>Genau. Der Minuend (478)<sub>9</sub> muss lediglich um einen Einer erhöht werden, um auf die Zahl (480)<sub>9</sub> zu kommen. Die Aufgabe (682)<sub>9</sub> – (480)<sub>9</sub> = (202)<sub>9</sub> ist eine in unseren Augen leicht zu berechnende Aufgabe. In einem zweiten Schritt muss nun nur noch die Differenz der Hilfsaufgabe wieder um einen Einer auf (203)<sub>9</sub> erhöht, also ausgeglichen, werden.</p>",
            },
            {
              correct: false,
              text: "Nähe des Minuenden an einer Zahl, von der der Subtrahend leicht abgezogen werden kann ",
              response: "Zwar liegt die (682)<sub>9</sub> nah an der (680)<sub>9</sub>, jedoch halten wir die Aufgabe (680)<sub>9</sub> – (478)<sub>9</sub> nicht für leichter zu berechnen als die Ausgangsaufgabe. Eine andere Veränderung führt unserer Ansicht nach zu einer Aufgabe, die bei der Berechnung der Ausgangsaufgabe hilft",
            },
            {
              correct: false,
              text: "Weil die Aufgabe im dekadischen Stellenwertsystem leicht zu berechnen ist.",
              response: "Wir halten es für umständlicher, die Aufgabe zunächst ins dekadische System zu übersetzten, um dann die Differenz wieder ins Stellenwertsystem zur Basis 9 zu übersetzen. Denken Sie im Stellenwertsystem zur Basis 9 und überlegen Sie, inwiefern eine Veränderung des Minuenden oder Subtrahenden zu einer leichter zu berechnenden Aufgabe führen kann.",
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
