function createObject() {
  let example = {
    id: "LBST_HR_Stempeln_Bsp1",
    workOrder:
      "Bild in Rückmeldung wird nicht angezeigt! Betrachten Sie die folgende Aufgabe: ",
    image: require("@/assets/hr/Stempeln/Bsp1/01.png"),
    elements: [
      [
        {
          question:
            "Welche halbschriftliche Strategie bietet sich als geschickteste an? Beziehen Sie in Ihre Überlegungen mit ein, dass die Aufgabe im Stellenwertsystem zur Basis 5 ist.",
          answers: [
            {
              correct: false,
              text: "Schrittweise",
              response: `<p>Zwar lässt sich diese Aufgabe auch mit der stellenweisen Strategie in geschickter Weise lösen, jedoch ist das Bestimmen der Schritte, um die Aufgabe einfach zu berechnen, nicht ganz unaufwändig. Möglich wäre hier zum Beispiel folgendes Vorgehen: </p><div id="LBST_HR_Stempeln_Bsp1_Bild"></div><p>Allerdings gibt es eine andere Strategie, um die Aufgabe noch einfacher und geschickter zu lösen.</p>`,
            },
            {
              correct: true,
              text: "Vereinfachen",
              response: "Genau, diese Strategie halten wir auch für passend.",
            },
            {
              correct: false,
              text: "Stellenweise",
              response:
                "Wir halten diese Strategie für die gestellte Aufgabe für nicht besonders naheliegend. Dadurch, dass in der 51er Stelle die maximale Bündelungseinheit 5 überschritten wird, gibt es eine andere Strategie, mit der sich die Aufgabe geschickter lösen lässt.",
            },
          ],
        },
      ],
      [
        {
          question: "Warum bietet sich diese Strategie an?",
          answers: [
            {
              correct: false,
              text:
                "Das Vereinfachen bietet sich im Stellenwertsystem zur Basis 5 immer an",
              response:
                "Das sehen wir anders. Natürlich kann man immer den ersten und zweiten Summanden gegensinnig verändern, jedoch führt das wie auch im dekadischen System nicht bei jeder Aufgabe dazu, dass diese leichter zu berechnen wäre.",
            },
            {
              correct: false,
              text:
                "Es gibt keine andere halbschriftliche Strategie, die sich hier anbietet ",
              response:
                "Das sehen wir nicht so. Zwar ist das Vereinfachen hier sicherlich ein geschicktes Vorgehen, jedoch ließe sich die Aufgabe zum Beispiel auch mit einer Hilfsaufgabe gut lösen. Auch andere halbschriftliche Strategien können angewendet werden, diese erfordern allerdings sicherlich bei der Bestimmung der Summe mehr Überlegungen.",
            },
            {
              correct: true,
              text:
                "Sowohl erster als auch zweiter Summand liegen nah an glatten Zahlenwerten",
              response:
                "<p>Genau. Der erste Summand (231)<sub>5</sub> muss lediglich um einen Einer verringert werden, um auf die Zahl (230)<sub>5</sub> zu kommen. Der zweite Summand kann durch die gegensinnige Veränderung, also die Erhöhung um einen Einer, auf (100)<sub>5</sub> verändert werden. Die Aufgabe (230)<sub>5</sub> + (100)<sub>5</sub> = (330)<sub>5</sub> ist eine in unseren Augen leicht zu berechnende Aufgabe, die aufgrund der gegensinnigen Veränderung direkt zur Summe der Ausgangsaufgabe führt.</p>",
            },
          ],
        },
      ],
    ],
  };
  return example;
}

export default createObject();
